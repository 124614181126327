import React from 'react';
import PropTypes from 'prop-types';
import {
  ConfirmationPopup, NumberInput, Selector, Notification,
} from 'shared/components';
import './styles.scss';

const TimeInput = ({
  value, // value in seconds
  onChange, // callback with new value in seconds
  id,
  disabled,
  min,
  error,
}) => {
  const timeUnits = [
    { label: __('Seconds'), value: 's', multiplier: 1 },
    { label: __('Minutes'), value: 'm', multiplier: 60 },
    { label: __('Hours'), value: 'h', multiplier: 3600 },
    { label: __('Days'), value: 'd', multiplier: 86400 },
  ];

  const getInitialUnit = (seconds) => {
    const validSeconds = Math.max(seconds || min || 1, min || 1);
    for (let i = timeUnits.length - 1; i >= 0; i -= 1) {
      const unit = timeUnits[i];
      if (validSeconds % unit.multiplier === 0) {
        return unit.value;
      }
    }
    return 's';
  };

  const getInitialValue = (seconds, unitValue) => {
    const validSeconds = Math.max(seconds || min || 1, min || 1);
    const unit = timeUnits.find(u => u.value === unitValue);
    return validSeconds / unit.multiplier;
  };

  const [selectedUnit, setSelectedUnit] = React.useState(getInitialUnit(value));
  const [inputValue, setInputValue] = React.useState(
    getInitialValue(value, getInitialUnit(value)),
  );

  const handleUnitChange = (newUnit) => {
    const oldUnit = timeUnits.find(u => u.value === selectedUnit);
    const newUnitData = timeUnits.find(u => u.value === newUnit);

    if (!oldUnit || !newUnitData) return;

    const totalSeconds = inputValue * oldUnit.multiplier;
    let newValue = totalSeconds / newUnitData.multiplier;

    if (newValue < 1) {
      newValue = 1;
      onChange(newValue * newUnitData.multiplier);
    } else {
      onChange(totalSeconds);
    }

    setSelectedUnit(newUnit);
    setInputValue(newValue);
    onChange(totalSeconds);
  };

  const handleValueChange = (newValue) => {
    if (newValue === null || newValue === undefined || newValue === 0) return;

    const unit = timeUnits.find(u => u.value === selectedUnit);
    if (!unit) return;

    const totalSeconds = newValue * unit.multiplier;
    setInputValue(newValue);
    onChange(totalSeconds);
  };

  return (
    <div className="time-input-wrapper">
      <NumberInput
        id={`${id}-input`}
        handleChange={handleValueChange}
        value={inputValue}
        error={error}
        min={min ? Math.ceil(min / timeUnits.find(u => u.value === selectedUnit).multiplier) : 1}
        disabled={disabled}
      />
      <Selector
        minWidth="100px"
        id={`${id}-unit`}
        options={timeUnits}
        value={selectedUnit}
        handleChange={handleUnitChange}
        disabled={disabled}
      />
    </div>
  );
};

TimeInput.propTypes = {
  value: PropTypes.number.isRequired, // value in seconds
  onChange: PropTypes.func.isRequired, // callback with new value in seconds
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  error: PropTypes.string,
};

TimeInput.defaultProps = {
  disabled: false,
  min: 1,
  error: '',
};


export const OAuthModals = ({
  isLoading,
  isDeleteConfirmationOpen,
  setDeleteConfirmationOpen,
  handleDeleteOauthClient,
  isRotateConfirmationOpen,
  setRotateConfirmationOpen,
  isRefreshConfirmationOpen,
  setRefreshConfirmationOpen,
  isRotateSecretModalOpen,
  setRotateSecretModalOpen,
  handleRotateSecret,
  handleRefreshSecret,
  rotateSecretData,
  setRotateSecretData,
  modalTitle,
  confirmButtonText,
  confirmButtonTheme,
}) => {
  const handleRotateFormSubmit = () => {
    if (rotateSecretData.secret_expiration_period === 0 || rotateSecretData.secret_rotated_expiration_period === 0) {
      Notification('error', __('Invalid time period'), __('Time periods must be greater than 0'));
      return;
    }

    if (modalTitle) {
      handleRotateSecret();
    } else {
      setRotateSecretModalOpen(false);
      setRotateConfirmationOpen(true);
    }
  };

  return (
    <>
      {isDeleteConfirmationOpen && (
        <ConfirmationPopup
          closeCb={() => setDeleteConfirmationOpen(false)}
          confirmCb={handleDeleteOauthClient}
          title={__('Delete OAuth Client')}
          confirmText={__('Delete')}
          theme="error"
          disabled={isLoading}
        >
          {__('Are you sure you want to delete the OAuth client? This action cannot be undone.')}
        </ConfirmationPopup>
      )}

      {isRotateSecretModalOpen && (
        <ConfirmationPopup
          closeCb={() => setRotateSecretModalOpen(false)}
          confirmCb={handleRotateFormSubmit}
          title={modalTitle || __('Rotate Client Secret')}
          confirmText={confirmButtonText || __('Continue')}
          theme={confirmButtonTheme}
          disabled={isLoading}
        >
          <div className="rotate-secret-form">
            <div className="rotate-input-wrapper">
              <div className="rotate-label">
                {__('Secret Expiration Period (time before newly generated secret expires)')}
              </div>
              <TimeInput
                value={rotateSecretData.secret_expiration_period}
                onChange={(newValue) => {
                  setRotateSecretData({
                    ...rotateSecretData,
                    secret_expiration_period: newValue,
                  });
                }}
                id="expiration-period"
                disabled={isLoading}
              />
            </div>

            <div className="rotate-input-wrapper">
              <div className="rotate-label">
                {__('Secret Rotation Grace Period (time before current secret expires)')}
              </div>
              <TimeInput
                value={rotateSecretData.secret_rotated_expiration_period}
                onChange={(newValue) => {
                  setRotateSecretData({
                    ...rotateSecretData,
                    secret_rotated_expiration_period: newValue,
                  });
                }}
                id="rotated-expiration-period"
                disabled={isLoading}
              />
            </div>
          </div>
        </ConfirmationPopup>
      )}

      {isRotateConfirmationOpen && (
        <ConfirmationPopup
          closeCb={() => setRotateConfirmationOpen(false)}
          confirmCb={handleRotateSecret}
          title={__('Confirm Secret Rotation')}
          confirmText={__('Rotate')}
          theme="warning"
          disabled={isLoading}
        >
          {__('This action will invalidate the current client secret after the rotation period. \nAny applications using the current secret will need to be updated. Do you want to continue?')}
        </ConfirmationPopup>
      )}

      {isRefreshConfirmationOpen && (
        <ConfirmationPopup
          closeCb={() => setRefreshConfirmationOpen(false)}
          confirmCb={handleRefreshSecret}
          title={__('Refresh Client Secret')}
          confirmText={__('Continue')}
          theme="warning"
          disabled={isLoading}
        >
          {__('This action will immediately invalidate the current client secret. Any applications using the current secret will stop working until updated. Do you want to continue?')}
        </ConfirmationPopup>
      )}
    </>
  );
};

OAuthModals.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isDeleteConfirmationOpen: PropTypes.bool,
  setDeleteConfirmationOpen: PropTypes.func,
  handleDeleteOauthClient: PropTypes.func,
  isRotateConfirmationOpen: PropTypes.bool,
  setRotateConfirmationOpen: PropTypes.func,
  isRefreshConfirmationOpen: PropTypes.bool,
  setRefreshConfirmationOpen: PropTypes.func,
  isRotateSecretModalOpen: PropTypes.bool.isRequired,
  setRotateSecretModalOpen: PropTypes.func.isRequired,
  handleRotateSecret: PropTypes.func.isRequired,
  handleRefreshSecret: PropTypes.func,
  rotateSecretData: PropTypes.shape({
    secret_expiration_period: PropTypes.number.isRequired,
    secret_rotated_expiration_period: PropTypes.number.isRequired,
    secret_remaining_rotation_period: PropTypes.number.isRequired,
  }).isRequired,
  setRotateSecretData: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtonTheme: PropTypes.string,
};

OAuthModals.defaultProps = {
  isDeleteConfirmationOpen: false,
  setDeleteConfirmationOpen: () => {},
  handleDeleteOauthClient: () => {},
  isRotateConfirmationOpen: false,
  setRotateConfirmationOpen: () => {},
  isRefreshConfirmationOpen: false,
  setRefreshConfirmationOpen: () => {},
  handleRefreshSecret: () => {},
  modalTitle: '',
  confirmButtonText: '',
  confirmButtonTheme: 'info',
};

export default OAuthModals;
