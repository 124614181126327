import { useState, useEffect } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import api from 'shared/api';

const useChartData = (requestUrl, dateFrom, dateTo, companyID) => {
  const APIdateFormat = 'YYYY-MM-DD';

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isDaily, setDaily] = useState(false);
  const [hasError, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const from = dateFrom.format(APIdateFormat);
  const to = dateTo.format(APIdateFormat);
  const duration = moment.duration(dateTo.diff(dateFrom)).asDays();
  setDaily(duration);

  const gteParam = `&ts__date__gte=${from}`;
  const lteParam = `&ts__date__lte=${to}`;
  const dailyParam = duration > 7 ? '&time=daily' : '';

  useEffect(() => {
    if (!requestUrl) {
      return;
    }
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(`${requestUrl}?company=${companyID}${gteParam}${lteParam}&order_by=ts${dailyParam}`);
        const chartData = get(response, 'data') || [];
        const sum = chartData.map(e => get(e, 'total_call_count') || 0).reduce((a, b) => a + b);
        setData(chartData);
        setTotal(sum);
      } catch (err) {
        setError(err);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [requestUrl, dateFrom, dateTo]);

  // eslint-disable-next-line object-curly-newline
  return { data, total, hasError, isLoading, isDaily };
};

export default useChartData;
