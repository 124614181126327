import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  sendErrorReport,
  displayValue,
  formatDate,
  isFeatureAllowed,
  makeProductDictionary,
  getLicenseStatus,
  checkUserPermission,
} from 'shared/helpers';
import {
  platformFeatures,
  defaultDateFormat,
  userPermissions,
} from 'shared/constants';
import {
  Button,
  ConfirmationPopup,
  ContentLoader,
  DescriptionTable,
  Forbidden,
  IconCheckmark,
  IconDisabled,
  JsonView,
  List,
  NotFound,
  Page,
  PermissionMissingNotificationTitle,
  StatusCard,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  TooltipHeader,
  Notification,
} from 'shared/components';
import {
  deleteCustomerAccount,
  fetchCustomerAccount,
  fetchAccountCustomersList,
  fetchAccountOrdersList,
  fetchAccountLicensesList,
} from 'src/customer/actions';
import CustomerAccountForm from '../components/CustomerAccountForm';
import CustomerAccountSSO from '../components/CustomerAccountSSO';
import AddCustomerToAccountForm from '../components/AddCustomerToAccountForm';
import CustomerAccountOAuth from './CustomerAccountOAuth';

const CustomerAccount = () => {
  const canManageCustomers = checkUserPermission(userPermissions.customers_write);
  const { accountId } = useParams();
  const history = useHistory();
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const products = useSelector(state => get(state, 'products.list'));

  const [isLoading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [customerAccount, setCustomerAccount] = useState(null);
  const [customersLoading, setCustomersLoading] = useState(true);
  const [customersList, setCustomersList] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [ordersList, setOrdersList] = useState([]);
  const [licensesLoading, setLicensesLoading] = useState(true);
  const [licensesList, setLicensesList] = useState([]);
  // forms state
  const [isFormDisplayed, setFormDisplay] = useState(false);
  const [isAccountDeleteConfirmationDisplayed, setAccountDeleteConfirmationDisplayed] = useState(false);
  const [accountDeleteLoading, setAccountDeleteLoading] = useState(false);
  const [isAddCustomerDisplayed, setAddCustomerDisplay] = useState(false);

  const getCustomerAccount = useCallback(() => {
    fetchCustomerAccount(companyID, accountId)
      .then((res) => {
        const data = get(res, 'data');
        setCustomerAccount(data);
        setLoading(false);
        return fetchAccountCustomersList(companyID, accountId);
      })
      .then((res) => {
        setCustomersList(get(res, 'data.results'));
        setCustomersLoading(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot load customer account details');
        setLoading(false);
        setCustomersLoading(false);
        setNotFound(true);
      });
  }, [companyID, accountId]);

  useEffect(() => {
    getCustomerAccount();
  }, [getCustomerAccount]);

  const redirectToCustomerPage = (rowData) => {
    const customerID = get(rowData, 'original.id');
    history.push(`/${companyID}/customers/${customerID}`);
  };

  const redirectToOrderPage = (rowData) => {
    const orderID = get(rowData, 'original.id');
    history.push(`/${companyID}/orders/${orderID}`);
  };

  const redirectToLicensePage = (rowData) => {
    const orderId = get(rowData, 'original.order');
    const licenseId = get(rowData, 'original.id');
    history.push(`/${companyID}/orders/${orderId}/${licenseId}`);
  };

  const handleTabChange = (index) => {
    if (index === 1 && !ordersList.length) {
      setOrdersLoading(true);
      fetchAccountOrdersList(companyID, accountId)
        .then((res) => {
          setOrdersList(get(res, 'data.results'));
          setOrdersLoading(false);
        })
        .catch((err) => {
          sendErrorReport(err, 'Cannot get customer account orders');
          setOrdersLoading(false);
        });
    }
    if (index === 2 && !licensesList.length) {
      setLicensesLoading(true);
      fetchAccountLicensesList(companyID, accountId)
        .then((res) => {
          setLicensesList(get(res, 'data.results'));
          setLicensesLoading(false);
        })
        .catch((err) => {
          sendErrorReport(err, 'Cannot get customer account licenses');
          setLicensesLoading(false);
        });
    }
  };

  const handleCompanyDelete = () => {
    const accountToDeleteID = get(customerAccount, 'id');
    setAccountDeleteLoading(true);

    deleteCustomerAccount(companyID, accountToDeleteID)
      .then(() => {
        setAccountDeleteConfirmationDisplayed(false);
        setAccountDeleteLoading(false);
        Notification('success', __('Changes saved successfully'), __('Customer account deleted'));
        history.push(`/${companyID}/customers/accounts`);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot delete customer account');
        setAccountDeleteLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleManageCustomerClick = (cb) => {
    if (!canManageCustomers) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.customers_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  if (isLoading) { return <Page><ContentLoader text={__('Getting customer account details')} /></Page>; }
  if (notFound) { return <Page><NotFound /></Page>; }

  const isAllowed = isFeatureAllowed(platformFeatures.extra_account_based_licensing);
  if (!isAllowed) {
    return (
      <Page title={__('Customer accounts')}>
        <div className="CustomerAccountsList">
          <Forbidden
            text={__('This feature is not available in your current plan type')}
            description={__('Contact sales if you wish to change this policy.')}
          />
        </div>
      </Page>
    );
  }

  const isSsoAllowed = isFeatureAllowed(platformFeatures.extra_single_sign_on);
  const mappedProducts = makeProductDictionary(products);

  return (
    <Page title={get(customerAccount, 'name')}>
      <div className="CustomerAccount">
        <div className="list-header">
          <div>
            <Button
              theme="info"
              onClick={() => handleManageCustomerClick(setFormDisplay)}
            >
              {__('Edit account')}
            </Button>
          </div>
          <div>
            <Button
              theme="error"
              onClick={() => handleManageCustomerClick(setAccountDeleteConfirmationDisplayed)}
            >
              {__('Delete account')}
            </Button>
          </div>
        </div>
        <div className="CustomerAccount-details">
          <DescriptionTable
            details={[
              { label: __('Code'), value: displayValue(get(customerAccount, 'code')) },
              { label: __('Reference'), value: displayValue(get(customerAccount, 'reference')) },
              { label: __('Name'), value: displayValue(get(customerAccount, 'name')) },
              { label: __('Description'), value: displayValue(get(customerAccount, 'description')) },
              { label: __('Email'), value: displayValue(get(customerAccount, 'email')) },
              { label: __('Address'), value: displayValue(get(customerAccount, 'address')) },
              { label: __('Phone Number'), value: displayValue(get(customerAccount, 'phone')) },
              {
                label: __('Metadata'),
                value: (
                  <JsonView
                    value={get(customerAccount, 'metadata')}
                    name="metadata"
                  />
                ),
              },
            ]}
          />
        </div>
        <Tabs onSelect={handleTabChange}>
          <TabsHeader>
            <Tab>{__('Customers')}</Tab>
            <Tab>{__('Orders')}</Tab>
            <Tab>{__('Licenses')}</Tab>
            {isSsoAllowed && <Tab>{__('Single Sign On')}</Tab>}
            <Tab>{__('OAuth')}</Tab>
          </TabsHeader>
          <TabContent>
            <div className="CustomerAccount-customers">
              <Button theme="default" size="sm" onClick={() => handleManageCustomerClick(setAddCustomerDisplay)}>
                {__('Add customer')}
              </Button>
              <List
                clickable
                columns={[
                  {
                    accessor: 'email',
                    Header: __('Email'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                    maxWidth: 300,
                  },
                  {
                    accessor: 'first_name',
                    Header: __('First Name'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                    maxWidth: 200,
                  },
                  {
                    accessor: 'last_name',
                    Header: __('Last Name'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                    maxWidth: 300,
                  },
                  {
                    accessor: 'phone',
                    Header: __('Phone Number'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                    maxWidth: 150,
                  },
                  {
                    accessor: 'reference',
                    Header: __('Reference'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                  },
                  {
                    accessor: 'labels',
                    Header: __('Labels'),
                    className: 'customer-label',
                    Cell: (cellInfo) => {
                      const labels = get(cellInfo, 'value') || [];
                      const labelTitles = labels.map(l => l.label).sort().join(', ');
                      return (
                        <div className="customerLabels">
                          <span className="label-titles">{labelTitles}</span>
                          <div className="label-colors-wrapper">
                            {labels.map(l => (
                              <div className="label-color" key={l.id} style={{ backgroundColor: l.color || '#949494' }} />
                            ))}
                          </div>
                        </div>
                      );
                    },
                  },
                ]}
                data={customersList || []}
                loading={customersLoading}
                minRows={2}
                handleClick={rowData => redirectToCustomerPage(rowData)}
              />
            </div>
          </TabContent>
          <TabContent>
            <div className="CustomerAccount-orders">
              <List
                clickable
                columns={[
                  {
                    accessor: 'created_at',
                    Header: __('Created on'),
                    Cell: cellInfo => formatDate(cellInfo.value),
                    width: 180,
                  },
                  {
                    accessor: 'store_id',
                    Header: __('Order ID'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                    minWidth: 200,
                  },
                  {
                    accessor: 'campaign_params',
                    Header: __('Campaign parameters'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                  },
                  {
                    accessor: 'download_id',
                    Header: __('Download ID'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                  },
                  {
                    accessor: 'has_active_licenses',
                    Header: __('Has active licenses'),
                    headerClassName: 'text-center',
                    className: 'text-center',
                    Cell: cellData => (cellData.value ? <IconCheckmark color="#10ac84" height="14px" /> : <IconDisabled color="red" height="14px" />),
                  },
                ]}
                data={ordersList || []}
                loading={ordersLoading}
                minRows={2}
                handleClick={rowData => redirectToOrderPage(rowData)}
              />
            </div>
          </TabContent>
          <TabContent>
            <div className="second-tab">
              <List
                clickable
                columns={[
                  {
                    accessor: 'created_at',
                    Header: __('Created on'),
                    Cell: cellInfo => formatDate(cellInfo.value),
                    width: 160,
                  },
                  {
                    accessor: 'license_key',
                    Header: __('License key'),
                    width: 200,
                  },
                  {
                    accessor: 'product.id',
                    Header: __('Product'),
                    Cell: cellInfo => displayValue(mappedProducts[cellInfo.value]),
                    maxWidth: 250,
                  },
                  {
                    accessor: 'license_type',
                    Header: __('License Type'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                    maxWidth: 200,
                  },
                  {
                    accessor: 'validity_period',
                    Header: () => TooltipHeader(__('Expiration Date')),
                    Cell: cellData => formatDate(cellData.value, defaultDateFormat),
                    maxWidth: 200,
                  },
                  {
                    accessor: 'times_activated',
                    Header: __('Times activated'),
                    maxWidth: 150,
                  },
                  {
                    accessor: 'is_trial',
                    className: 'text-center',
                    Header: __('Is trial'),
                    headerClassName: 'text-center',
                    Cell: cellData => (cellData.value ? <IconCheckmark color="#10ac84" height="14px" /> : <IconDisabled color="#aaa" height="14px" />),
                    width: 80,
                  },
                  {
                    accessor: 'enabled',
                    className: 'text-center',
                    Header: __('Status'),
                    headerClassName: 'text-center',
                    Cell: (rowData) => {
                      const status = getLicenseStatus(
                        get(rowData, 'original.enabled'),
                        get(rowData, 'original.active'),
                      );
                      return (
                        <StatusCard
                          status={status.positive ? 'success' : 'error'}
                          text={__(status.message)}
                        />
                      );
                    },
                    minWidth: 100,
                  },
                ]}
                data={licensesList}
                loading={licensesLoading}
                minRows={2}
                handleClick={rowData => redirectToLicensePage(rowData)}
              />
            </div>
          </TabContent>
          <TabContent>
            <CustomerAccountSSO accountID={accountId} redirectUrl={get(customerAccount, 'keycloak_redirect_uri')} refetchAccount={getCustomerAccount} />
          </TabContent>
          <TabContent>
            <CustomerAccountOAuth accountID={accountId} companyID={companyID} />
          </TabContent>
        </Tabs>
        {isFormDisplayed && (
          <CustomerAccountForm
            companyID={companyID}
            customerAccount={customerAccount}
            closeCb={() => {
              setFormDisplay(false);
            }}
            confirmCb={() => {
              setFormDisplay(false);
              getCustomerAccount();
            }}
          />
        )}
        {isAccountDeleteConfirmationDisplayed && (
          <ConfirmationPopup
            closeCb={() => {
              setAccountDeleteConfirmationDisplayed(false);
            }}
            confirmCb={handleCompanyDelete}
            title={`${__('Are you sure you want to delete this customer account')}?`}
            confirmText={__('Delete')}
            theme="error"
            disabled={accountDeleteLoading}
          >
            <div className="feature-delete-popup-inner">
              {`${get(customerAccount, 'name')} ${__('will be removed and its customer will be unassigned from this account')}.`}
            </div>
          </ConfirmationPopup>
        )}
        {isAddCustomerDisplayed && (
          <AddCustomerToAccountForm
            closeForm={() => setAddCustomerDisplay(false)}
            company={companyID}
            customerAccountID={Number(accountId)}
            updateCustomer={() => {
              setAddCustomerDisplay(false);
              setCustomersLoading(true);
              getCustomerAccount();
            }}
          />
        )}
      </div>
    </Page>
  );
};

export default CustomerAccount;
