import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Label,
  List,
  Notification,
  PermissionMissingNotificationTitle,
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  TextInput,
  Subtitle,
} from 'shared/components';
import { userPermissions, platformFeatures } from 'shared/constants';
import {
  sendErrorReport,
  displayValue,
  checkUserPermission,
  isFeatureEnabled,
} from 'shared/helpers';
import {
  debouncedValidateCompanyName,
  debouncedValidateCompanyCode,
} from 'shared/validation';
import { getCompanySilent } from 'src/company/actions';
import { getUserCompanies, updateCompany, set2FaRequiredOnCompany } from 'src/account/actions';
import { ApiKeys, SsoSettings, Integrations } from './components';
import './styles.scss';

const SettingsContainer = () => {
  const canManageSettings = checkUserPermission(userPermissions.settings_write);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const companies = useSelector(state => get(state, 'account.companies'));
  const companyDetails = useSelector(state => get(state, 'company.details'));

  const companyID = get(companyDetails, 'id');
  const initialCompanyName = get(companyDetails, 'name');
  const initialCompanyCode = get(companyDetails, 'code');
  const connectedCompanies = get(companyDetails, 'connected_companies') || [];
  const hasConnectedCompanies = connectedCompanies && connectedCompanies.length > 0;

  const twofaCompanyRequired = get(companyDetails, 'twofa_required');

  const [isLoading, setLoading] = useState(false);
  const [twofaChangeLoading, setTwofaChangeLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [companyName, setCompanyName] = useState(initialCompanyName || '');
  const [companyNameError, setCompanyNameError] = useState('');
  const [companyCode, setCompanyCode] = useState(get(companyDetails, 'code') || '');
  const [companyCodeError, setCompanyCodeError] = useState('');
  const [companyCodeLoading, setCompanyCodeLoading] = useState(false);

  const [sdkAllow, setSdkAllow] = useState(get(companyDetails, 'sdk_allow_inactive_get_files'));
  const [trialAllow, setTrialAllow] = useState(get(companyDetails, 'trial_allow_multiple_licenses'));
  const [allowSDKPassword, setAllowSDKPassword] = useState(get(companyDetails, 'allow_sdk_password_change'));
  const [allowOfflineFloatingCloud, setAllowOfflineFloatingCloud] = useState(get(companyDetails, 'allow_offline_floating_cloud'));
  const [isOauthRequired, setIsOauthRequired] = useState(get(companyDetails, 'is_oauth_required'));
  const isOauthEnabled = isFeatureEnabled(platformFeatures.extra_oauth);

  const [selectedTab, setSelectedTab] = useState(0);
  // const openSelectedTab = i => setSelectedTab(i);

  const handleCompanyCodeChange = (val) => {
    setDirty(true);
    setCompanyCode(val);

    if (!val) {
      setCompanyCodeError('');
    } else {
      setCompanyCodeLoading(true);
      debouncedValidateCompanyCode(val)
        .then((err) => {
          setCompanyCodeError(err);
          setCompanyCodeLoading(false);
        });
    }
  };

  const handleCompanyUpdate = (data) => {
    updateCompany(companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        setLoading(false);
        setTwofaChangeLoading(false);
        setDirty(false);
        if (companyName !== initialCompanyName) {
          dispatch(getUserCompanies());
        } else {
          dispatch(getCompanySilent(companyID));
        }
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit company data', data);
        setLoading(false);
        setTwofaChangeLoading(false);
        setDirty(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleCompany2FaRequired = () => {
    set2FaRequiredOnCompany(companyID)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        setLoading(false);
        setDirty(false);
        if (companyName !== initialCompanyName) {
          dispatch(getUserCompanies());
        } else {
          dispatch(getCompanySilent(companyID));
        }
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot set company 2fa required');
        setLoading(false);
        setTwofaChangeLoading(false);
        setDirty(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const submitData = () => {
    if (isLoading || !dirty || companyNameError) {
      return false;
    }
    setLoading(true);

    const data = {
      name: companyName,
      code: companyCode || undefined,
      sdk_allow_inactive_get_files: sdkAllow,
      trial_allow_multiple_licenses: trialAllow,
      twofa_required: twofaCompanyRequired,
      allow_sdk_password_change: allowSDKPassword,
      allow_offline_floating_cloud: allowOfflineFloatingCloud,
      is_oauth_required: isOauthRequired,
    };

    handleCompanyUpdate(data);
    return true;
  };

  const handleCompany2FAChange = (isRequired) => {
    setTwofaChangeLoading(true);
    if (!isRequired) {
      const data = { twofa_required: false };
      handleCompanyUpdate(data);
      return true;
    }

    handleCompany2FaRequired();
    return true;
  };

  useEffect(() => {
    const queryParams = get(location, 'search');
    if (!queryParams) {
      setSelectedTab(0);
    }
    if (queryParams.indexOf('st=0') >= 0) {
      setSelectedTab(0);
    }
    if (queryParams.indexOf('st=1') >= 0) {
      setSelectedTab(1);
    }
    if (queryParams.indexOf('st=2') >= 0) {
      setSelectedTab(2);
    }
    if (queryParams.indexOf('st=3') >= 0) {
      setSelectedTab(3);
    }
    if (queryParams.indexOf('st=4') >= 0) {
      setSelectedTab(4);
    }
  }, [location]);

  const handleManageSettingsClick = (cb) => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  return (
    <div className="SettingsContainer">
      <Prompt
        when={dirty}
        message={__('You have unsaved changes. Are you sure you want to leave?')}
      />
      <Page title={__('Settings')}>
        <Tabs
          defaultFocus
          selectedIndex={selectedTab}
          onSelect={(tabIndex) => {
            history.push({
              search: `?st=${tabIndex}`,
            });
            setSelectedTab(tabIndex);
          }}
          forceRenderTabPanel
        >
          <TabsHeader>
            <Tab>{__('Preferences')}</Tab>
            <Tab>{__('Keys')}</Tab>
            <Tab>{__('Integrations')}</Tab>
            <Tab>{__('SSO Settings')}</Tab>
            {hasConnectedCompanies && <Tab>{__('Linked accounts')}</Tab>}
          </TabsHeader>
          <TabContent>
            <div className="form-cont">
              <Subtitle text={__('General')} />
              <form onSubmit={submitData}>
                <div className="row">
                  <Label inputId="company-name-input" text={__('Company name')} />
                  <TextInput
                    id="company-name-input"
                    value={companyName}
                    error={companyNameError}
                    handleChange={(val) => {
                      setDirty(true);
                      setCompanyName(val);
                      debouncedValidateCompanyName(val, companies).then(err => setCompanyNameError(err));
                    }}
                  />
                </div>
                <div className="row">
                  <Label inputId="company-code-input" text={__('Company code')} />
                  <TextInput
                    id="company-code-input"
                    placeholder={__('Eg. QQ')}
                    disabled={!!initialCompanyCode}
                    value={companyCode}
                    error={companyCodeError}
                    loading={companyCodeLoading}
                    handleChange={handleCompanyCodeChange}
                  />
                </div>
                <div className="row checkboxes">
                  <Checkbox
                    label={__('Allow inactive files for SDK')}
                    inputId="sdk-allow"
                    checked={sdkAllow}
                    handleChange={(val) => {
                      setDirty(true);
                      setSdkAllow(val);
                    }}
                  />
                </div>
                <div className="row">
                  <Checkbox
                    label={__('Allow multiple licenses on trial')}
                    inputId="trial-allow"
                    checked={trialAllow}
                    handleChange={(val) => {
                      setDirty(true);
                      setTrialAllow(val);
                    }}
                  />
                </div>
                <div className="row">
                  <Checkbox
                    label={__('Allow password change from SDK')}
                    inputId="alow-sdk-password-change"
                    checked={allowSDKPassword}
                    handleChange={(val) => {
                      setDirty(true);
                      setAllowSDKPassword(val);
                    }}
                  />
                </div>
                <div className="row">
                  <Checkbox
                    label={__('Allow floating cloud offline activation')}
                    inputId="alow-offline-floating-cloud"
                    checked={allowOfflineFloatingCloud}
                    handleChange={(val) => {
                      setDirty(true);
                      setAllowOfflineFloatingCloud(val);
                    }}
                  />
                </div>
                <div className="row flex-row">
                  <Checkbox
                    label={__('oAuth is required for License API calls')}
                    inputId="is-oauth-required"
                    checked={isOauthRequired}
                    disabled={!isOauthEnabled}
                    handleChange={(val) => {
                      setDirty(true);
                      setIsOauthRequired(val);
                    }}
                  />
                  {!isOauthEnabled && (
                    <div className="oauth-message">
                      {__('(This feature is not available for your account. Contact sales to enable oAuth.)')}
                    </div>
                  )}
                </div>
                <Button
                  theme="success"
                  onClick={() => handleManageSettingsClick(submitData)}
                  disabled={isLoading || !companyName}
                >
                  {__('Save changes')}
                </Button>
              </form>
              <div className="Security">
                <div className="section-header">
                  <Subtitle text={__('Security')} />
                </div>
                <div className="section-body">
                  <div className="description">
                    {__('Set two-factor authentication required for your company account.')}
                  </div>
                  <Button
                    onClick={() => {
                      handleCompany2FAChange(!twofaCompanyRequired);
                    }}
                    theme={twofaCompanyRequired ? 'error' : 'info'}
                    size="sm"
                    disabled={twofaChangeLoading}
                    loading={twofaChangeLoading}
                  >
                    {twofaCompanyRequired ? __('Disable 2FA') : __('Enable 2FA')}
                  </Button>
                </div>
              </div>
            </div>
          </TabContent>
          <TabContent>
            <ApiKeys />
          </TabContent>
          <TabContent>
            <Integrations />
          </TabContent>
          <TabContent>
            <SsoSettings />
          </TabContent>
          {hasConnectedCompanies && (
            <TabContent>
              <List
                data={connectedCompanies}
                columns={[
                  {
                    accessor: 'name',
                    Header: __('Company name'),
                    Cell: cellInfo => displayValue(cellInfo.value),
                  },
                ]}
                clickable
                handleClick={(rowData) => {
                  const linkedCompanyID = get(rowData, 'original.id');
                  const isAccAvailable = companies.find(c => c.id === linkedCompanyID);
                  if (!isAccAvailable) {
                    Notification('error', __('Company page is not available'), __('You are not user in that company account'));
                    return false;
                  }
                  history.push(`/${linkedCompanyID}`);
                  return true;
                }}
                minRows={2}
                pageSize={20}
              />
            </TabContent>
          )}
        </Tabs>
      </Page>
    </div>
  );
};

export default SettingsContainer;
